// extracted by mini-css-extract-plugin
export var active = "Advisor-module--active--7d66g";
export var advisor = "Advisor-module--advisor--uDP6s";
export var anchor = "Advisor-module--anchor--udSjQ";
export var blockOne = "Advisor-module--blockOne--JLRlK";
export var blockTwo = "Advisor-module--blockTwo--wJOMW";
export var blocks = "Advisor-module--blocks--kJRoh";
export var box = "Advisor-module--box--bmfuz";
export var button = "Advisor-module--button--OrnJD";
export var circle = "Advisor-module--circle--O-jGz";
export var container = "Advisor-module--container--CH4O+";
export var header = "Advisor-module--header--JpTF3";
export var heading = "Advisor-module--heading--g6fU9";
export var headingSub = "Advisor-module--headingSub--iYqq5";
export var icon = "Advisor-module--icon--DvCjB";
export var informationForm = "Advisor-module--informationForm--MTEr8";
export var linkButton = "Advisor-module--linkButton--Mmk0J";
export var list = "Advisor-module--list--jMphI";
export var listItem = "Advisor-module--listItem--A9plS";
export var main = "Advisor-module--main--iTcpS";
export var message = "Advisor-module--message--w9Iv9";
export var noResults = "Advisor-module--noResults--PXDdk";
export var open = "Advisor-module--open--sCsBh";
export var paragraph = "Advisor-module--paragraph--+l-1l";
export var priceTiers = "Advisor-module--priceTiers--NYmCy";
export var quickNavigation = "Advisor-module--quickNavigation--CZLOq";
export var reviewTotal = "Advisor-module--reviewTotal--1igXc";
export var reviews = "Advisor-module--reviews--Xyfhh";
export var searching = "Advisor-module--searching--6Ohz8";
export var stars = "Advisor-module--stars--I6aDk";
export var subHeader = "Advisor-module--subHeader--cOh0I";