import React from "react";

import { Helmet } from "react-helmet";

import { Dispatch } from "redux";
import { MouseEvent } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { navigate } from "gatsby";
import { useStaticQuery } from "gatsby";

import { createRef } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector} from "react-redux";
import { useState } from "react";
import Box from "@js/component/Box";
import Button from "@js/component/Button";
import CheckIcon from "@js/component/icon/CheckIcon";
import Heading from "@js/component/Heading";
import InformationForm from "@js/component/InformationForm";
import LinkButton from "@js/component/LinkButton";
import Main from "@js/component/Main";
import PriceTier from "@js/component/PriceTier";

import StoreState from "@js/store/StoreState";

import {
    setAdvisorUrl,
    setArea,
    setAreaCode, setAreaManual,
    setBuildingType, setHasCompleted
} from "@js/actionCreators/informationActionCreators";
import { setZipCode } from "@js/actionCreators/informationActionCreators";
import { setHouseNumber } from "@js/actionCreators/informationActionCreators";
import { setHouseNumberAddition } from "@js/actionCreators/informationActionCreators";

import useDebounce from "@js/hook/useDebounce";
import BreadCrumbs from "@js/component/BreadCrumbs";
import AdvisorReviews from "@js/component/AdvisorReviews";

import * as classNames from "@css/component/template/Advisor.module.scss";

/**
 * @type FilterData
 */
type FilterData = {
    filter: {
        options: {
            methodology: {
                label: string;
                value: string;
            }[];
        };
    };
};

/**
 * @type LogoPlaceholderData
 */
type LogoPlaceholderData = {
    allFile: {
        edges: {
            node: {
                publicURL: string;
            }
        }[]
    };
};

/**
 * @type Data
 */
type Data = FilterData & LogoPlaceholderData;

/**
 * PriceTier
 */
type PriceTier = {
    advisor: {
        companyImage: {
            originalName: string;
            url: string;
        };
        companyName: string;
        deliveryTime: number;
        full: boolean;
        id: number;
        reviewAverage: number;
        reviewAveragePercentage: number;
        reviewTotalCount: number;
        reviews: {
            advisor: number;
            recentReviews: {
                advisor: number;
                billingCity: string;
                billingHouseNumber: number;
                billingHouseNumberSuffix: string;
                billingStreet: string;
                billingZipcode: string;
                buildingOwnerAffix: string;
                buildingOwnerCompanyName: string;
                buildingOwnerInitials: string;
                buildingOwnerName: string;
                buildingOwnerTitle: string;
                createdAt: string;
                deliveryTime: number;
                review: number;
                reviewMessage: string;
                status: string;
                url: string;
            }[];
            scores: {
                1: number;
                2: number;
                3: number;
                4: number;
                5: number;
            };
            stats: {
                averageScore: number;
                totalReviews: number;
                wilsonScore: number;
            };
        }[];
        url: string;
    };
    hash: string;
    id: number;
    methodology: string;
    buildingTypeCategory: string;
    price: number;
    requiresQuote: boolean;
};

/**
 * @type AdvisorProps
 */
type AdvisorProps = {
    pageContext: {
        city: string;
        companyImageUrl: string;
        companyName: string;
        companyUrl: string;
        companyPromo: string;
        databaseId: number;
        reviews: {
            advisor: number;
            reviews: {
                advisor: number;
                billingCity: string;
                billingHouseNumber: number;
                billingHouseNumberSuffix: string;
                billingStreet: string;
                billingZipcode: string;
                buildingOwnerAffix: string;
                buildingOwnerCompanyName: string;
                buildingOwnerInitials: string;
                buildingOwnerName: string;
                buildingOwnerTitle: string;
                createdAt: string;
                deliveryTime: number;
                review: number;
                reviewMessage: string;
                status: string;
                url: string;
            }[];
            scores: {
                1: number;
                2: number;
                3: number;
                4: number;
                5: number;
            };
            stats: {
                averageScore: number;
                totalReviews: number;
                wilsonScore: number;
            };
        };
        strongPoints: {
            message: string;
        }[];
        metaTitle: string;
        metaDescription?: string;
        metaKeywords?: string;
        metaUrl: string;
        title_h1: string;
    };
};

/**
 * @const Advisor
 */
const Advisor = (props: AdvisorProps) => {
    const {
        pageContext
    } = props;

    const dispatch = useDispatch();
    const debounce = useDebounce();

    const formRef = createRef<HTMLFormElement>();

    const [isBlockOneOpen, setIsBlockOneOpen] = useState(false);
    const [isFetchingPriceTiers, setIsFetchingPriceTiers] = useState(true);

    const [tempBuildingType, setTempBuildingType] = useState("");
    const [tempZipCode, setTempZipCode] = useState("");
    const [tempHouseNumber, setTempHouseNumber] = useState("");
    const [tempHouseNumberAddition, setTempHouseNumberAddition] = useState("");

    const [priceTiers, setPriceTiers] = useState<PriceTier[]>([]);

    const advisorUrl = useSelector((storeState: StoreState) => storeState.information.advisorUrl);
    const areaCode = useSelector((storeState: StoreState) => storeState.information.areaCode);
    const areaManual = useSelector((storeState: StoreState) => storeState.information.areaManual);
    const buildingType = useSelector((storeState: StoreState) => storeState.information.buildingType);
    const buildingTypeGroup = useSelector((storeState: StoreState) => storeState.information.buildingTypeGroup);
    const hasCompleted = useSelector((storeState: StoreState) => storeState.information.hasCompleted);
    const houseNumber = useSelector((storeState: StoreState) => storeState.information.houseNumber);
    const houseNumberAddition = useSelector((storeState: StoreState) => storeState.information.houseNumberAddition);
    const zipCode = useSelector((storeState: StoreState) => storeState.information.zipCode);
    const [isInvalidBag, setIsInvalidBag] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const isReady =
        buildingType &&
        zipCode &&
        houseNumber;

    const fetchArea = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/bag/${ buildingTypeGroup }/${ tempZipCode }/${ tempHouseNumber }/${ tempHouseNumberAddition }`);

        const data = await response.json();

        if (!(data.status == "success")) {
            setIsInvalidBag(true);

            if (!areaManual) {
                dispatch(setArea(''));
                dispatch(setAreaCode(''));
            }

            return;
        }

        setIsInvalidBag(false);
        dispatch(setArea(data.result.area));
        dispatch(setAreaCode(data.result.areaCode));
        dispatch(setAreaManual(false));
    };

    const onUpdateButtonClick = async (event: MouseEvent) => {
        event.preventDefault();

        if (formRef.current?.reportValidity()) {
            await fetchArea();

            if(buildingTypeGroup === 'business') {
                dispatch(setBuildingType(buildingTypeGroup[0].toUpperCase() + buildingTypeGroup.slice(1)));
            } else {
                dispatch(setBuildingType(tempBuildingType));
            }

            dispatch(setZipCode(tempZipCode));
            dispatch(setHouseNumber(tempHouseNumber));
            dispatch(setHouseNumberAddition(tempHouseNumberAddition));

            setIsBlockOneOpen(false);

            search();
        }
    };

    const search = () => {
        if(!areaCode) {
            return;
        }

        setIsFetchingPriceTiers(true);

        dispatch((() => async (dispatch: Dispatch, getState: () => StoreState) => {
            const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/search`, {
                body: JSON.stringify({
                    filters: {
                        area: getState().information.areaCode || undefined,
                        advisor: pageContext.databaseId,
                        buildingType: getState().information.buildingType || undefined,
                        buildingTypeCategory: getState().information.buildingTypeGroup || undefined,
                        targetZipCode: getState().information.zipCode || undefined
                    }
                }),
                method: "POST"
            });

            const data = await response.json();

            if ( data.result.length > 0 ) {
                setPriceTiers(data.result);
                setIsFetchingPriceTiers(false);

                if (hasCompleted && !advisorUrl) {
                    dispatch(setHasCompleted(false));
                    dispatch(setAdvisorUrl(data.result[0].advisor.url));
                }

                if (advisorUrl === data.result[0].advisor.url) {
                    dispatch(setAdvisorUrl(""));
                }
            } else {
                setIsFetchingPriceTiers(false);
            }
        })());
    };

    useEffect(() => {
        if (priceTiers.length > 0) {
            const dataLayerData = {
                "event": "push",
                "priceTiers": priceTiers.map((priceTier) => ({
                    "companyName": priceTier.advisor.companyName,
                    "companyID": priceTier.advisor.id,
                    "priceType": priceTier.price ? "price" : "offer",
                    "deliveryDays": priceTier.advisor.deliveryTime,
                    "availability": !(priceTier.advisor.full),
                    "method": priceTier.methodology,
                    "reviewScore": priceTier.advisor.reviewAveragePercentage,
                    "reviewAmount": priceTier.advisor.reviewTotalCount,
                    "price": priceTier.price ? priceTier.price : ""
                }))
            };

            dataLayerData.priceTiers.forEach((priceTier, index) => {
                if (priceTiers[index].price) {
                    dataLayerData.priceTiers[index].price = priceTiers[index].price;
                }
            });

            typeof window == "object" && window.gtag && window.gtag("set", dataLayerData);

        }
    }, [priceTiers]);

    useEffect(() => {
        setTempBuildingType(buildingType);
        setTempZipCode(zipCode);
        setTempHouseNumber(houseNumber);
        setTempHouseNumberAddition(houseNumberAddition);
    }, [buildingTypeGroup]);

    useEffect(() => {
        debounce(() => {
            void fetchArea();

            if(!tempZipCode || !tempHouseNumber) {
                setIsButtonDisabled(true);
                dispatch(setAreaManual(false));
            } else {
                setIsButtonDisabled(false);
            }
        });

    }, [tempZipCode, tempHouseNumber, tempHouseNumberAddition, buildingTypeGroup]);

    useEffect(() => {
        search();
    }, []);

    const data: Data = useStaticQuery(graphql`
        query {
            filter {
                options {
                    methodology {
                        label
                        value
                    }
                }
            }
            allFile(filter: {relativePath: {eq: "logo-placeholder.png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `);

    const aggregateRating = {
        "@type": "AggregateRating",
        "bestRating": 5,
        "ratingValue": pageContext.reviews.stats.averageScore,
        "reviewCount": pageContext.reviews.stats.totalReviews,
        "worstRating": 0
    };

    const replaceVariables = {
        city: pageContext.city,
        name: pageContext.companyName
    };

    Object.entries(replaceVariables).forEach(([searchWord, replaceWord]) => {
        pageContext.metaTitle = pageContext.metaTitle ? pageContext.metaTitle.replace(`{${ searchWord }}`, replaceWord) : '';
        pageContext.title_h1 = pageContext.title_h1 ? pageContext.title_h1.replace(`{${ searchWord }}`, replaceWord) : '';
        pageContext.metaDescription = pageContext.metaDescription ? pageContext.metaDescription.replace(`{${ searchWord }}`, replaceWord) : '';
    });

    const json = {
        "@context": "https://schema.org/",
        "@type": "Organization",
        "aggregateRating": pageContext.reviews.reviews.length > 0 ? aggregateRating : undefined,
        "description": `Een energielabel aanvragen voor je huis in ${ pageContext.city }? Vraag gemakkelijk & snel je energielabel aan bij ${ pageContext.companyName } via Woninglabel.nl!`,
        "name": `Energielabel aanvragen in ${ pageContext.city }? ${ pageContext.companyName } via Woninglabel.nl`,
    };

    const placeHolderImage = process.env.SITE_URL + data.allFile.edges[0].node.publicURL;

    return (
        <Main className={ classNames.main }>
            <Helmet>
                <title>{ pageContext.metaTitle ? pageContext.metaTitle : `Energielabel aanvragen in ${ pageContext.city }? ${ pageContext.companyName } via Woninglabel.nl` }</title>
                { pageContext.metaDescription &&<meta content={pageContext.metaDescription} name="description" /> }
                { pageContext.metaDescription &&<meta content={pageContext.metaDescription} property="og:description" /> }
                { pageContext.metaTitle && <meta content={pageContext.metaTitle} property="og:title" /> }
                { pageContext.metaKeywords && <meta content={pageContext.metaKeywords} property="og:keywords" /> }
                { pageContext.metaUrl && <meta content={pageContext.metaUrl} property="og:url" /> }
                <meta content="woninglabel.nl" property="og:site_name" />
                <meta content="advisor" property="og:type" />
                <meta content={ pageContext.companyImageUrl ? pageContext.companyImageUrl : placeHolderImage } property="og:image" />

                <script type="application/ld+json">
                    { JSON.stringify(json) }
                </script>
            </Helmet>
            <div className={ classNames.container }>
                <BreadCrumbs
                    breadCrumbs={ [{
                        text: pageContext.companyName,
                        url: pageContext.companyUrl
                    }] }
                    parents="over-ons/energielabel-adviseurs"
                />
                <section className={ classNames.advisor }>
                    <LinkButton className={ classNames.linkButton } to="/over-ons/energielabel-adviseurs">Alle energielabel-adviseurs</LinkButton>
                    <div className={ classNames.blocks }>
                        <div className={ clsx(classNames.blockOne, {
                            [classNames.open]: isBlockOneOpen
                        }) }>
                            <LinkButton className={ classNames.linkButton } to="/over-ons/energielabel-adviseurs">Alle energielabel-adviseurs</LinkButton>
                            <Box className={ classNames.box }>
                                <header className={ classNames.header }>
                                    <Heading className={ classNames.heading } element="h2" variant="small">
                                        Jouw gegevens
                                    </Heading>
                                </header>
                                <InformationForm
                                    buildingTypeValue={ tempBuildingType }
                                    className={ classNames.informationForm }
                                    formRef={ formRef }
                                    isButtonDisabled={ !areaCode || !buildingType || isButtonDisabled }
                                    houseNumberValue={ tempHouseNumber }
                                    houseNumberAdditionValue={ tempHouseNumberAddition }
                                    onBuildingTypeChange={ (event) => {
                                        setTempBuildingType(event.currentTarget.value);
                                        dispatch(setBuildingType(event.currentTarget.value));
                                    }}
                                    onButtonClick={ onUpdateButtonClick }
                                    onZipCodeChange={ (event) => {
                                        setTempZipCode(event.currentTarget.value);
                                        areaManual && dispatch(setAreaManual(false));
                                    }}
                                    onHouseNumberChange={ (event) => {
                                        setTempHouseNumber(event.currentTarget.value);
                                        areaManual && dispatch(setAreaManual(false));
                                    }}
                                    onHouseNumberAdditionChange={ (event) => {
                                        setTempHouseNumberAddition(event.currentTarget.value);
                                        areaManual && dispatch(setAreaManual(false));
                                    }}
                                    variant="sidebar"
                                    zipCodeValue={ tempZipCode}
                                    isInvalidBag={ isInvalidBag }
                                />
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            <header className={ classNames.header }>
                                <Heading className={ classNames.heading } element="h1" variant="large">
                                    { pageContext.title_h1 ? pageContext.title_h1 : pageContext.companyName }
                                </Heading>
                                <Button
                                    className={ classNames.button }
                                    onClick={ () => setIsBlockOneOpen(true) }
                                    type="button"
                                >
                                    Mijn gegevens
                                </Button>
                            </header>
                            { isReady && isFetchingPriceTiers &&
                                <div className={ classNames.searching }>We zoeken de producten van deze energielabel-adviseur</div>
                            }
                            { isReady && !(isFetchingPriceTiers) && priceTiers.length > 0 &&
                                <section className={ classNames.priceTiers }>
                                    { priceTiers
                                        .map((priceTier) => priceTier.methodology)
                                        .filter((priceTier, index, array) => array.indexOf(priceTier) == index)
                                        .map((methodology) => (
                                            <Box className={ classNames.box } element="section" key={ methodology }>
                                                <Heading className={ classNames.heading } element="h2">{
                                                    data.filter.options.methodology.find((m) => {
                                                        return m.value == methodology;
                                                    })?.label
                                                }</Heading>
                                                <ul className={ classNames.list }>
                                                    { priceTiers
                                                        .filter((priceTier) => priceTier.methodology == methodology)
                                                        .map((priceTier) => (
                                                            <li className={ classNames.listItem } key={ priceTier.id }>
                                                                <PriceTier
                                                                    advisor={ priceTier.advisor }
                                                                    advisorId={ priceTier.advisor.id }
                                                                    advisorUrl={ `/over-ons/energielabel-adviseurs/${ priceTier.advisor.url }` }
                                                                    buttonProps={{
                                                                        disabled: priceTier.advisor.full
                                                                    }}
                                                                    buttonText="Aanvragen"
                                                                    companyName={ priceTier.advisor.companyName }
                                                                    deliveryTime={ priceTier.advisor.deliveryTime }
                                                                    image={ priceTier.advisor.companyImage.url }
                                                                    onButtonClick={
                                                                        () => navigate(`/aanvragen/${ priceTier.hash }`)
                                                                    }
                                                                    price={ priceTier.price?.toString() }
                                                                    reviewAveragePercentage={
                                                                        priceTier.advisor.reviewAveragePercentage
                                                                    }
                                                                    reviewTotalCount={
                                                                        priceTier.advisor.reviewTotalCount
                                                                    }
                                                                    buildingTypeCategory={ priceTier.buildingTypeCategory }
                                                                />
                                                            </li>
                                                    )) }
                                                </ul>
                                            </Box>
                                    )) }
                                </section>
                            }
                            { isReady && !(isFetchingPriceTiers) && priceTiers.length == 0 &&
                                <Box className={ classNames.noResults }>
                                    <p className={ classNames.paragraph }>
                                        Deze energielabel-adviseur is helaas niet actief bij jou in de buurt.
                                        <br />Gebruik de onderstaande knop om energielabel-adviseurs bij jou in de buurt te vergelijken.
                                    </p>
                                    <Button className={ classNames.button } onClick={ () => { navigate(`/vergelijk/`) } } type="button">
                                        Vind de beste energielabel-adviseur
                                    </Button>
                                </Box>
                            }
                            { !(isReady) &&
                                <section className={ classNames.message }>
                                    Alle producten van { pageContext.companyName } bekijken?
                                    Vul links jouw gegevens in en je ziet meteen de voor jou beschikbare producten.
                                </section>
                            }
                            <div className={ classNames.blocks }>
                                <div className={ classNames.blockOne } id="information">
                                    <Box className={ classNames.box }>
                                        <Heading className={ classNames.heading } element="h2">
                                            Informatie over { pageContext.companyName }
                                        </Heading>
                                        <p className={ classNames.paragraph }>{ pageContext.companyPromo }</p>
                                    </Box>
                                </div>
                                { pageContext.strongPoints &&
                                    <div className={ classNames.blockTwo }>
                                        <Box className={ classNames.box }>
                                            <Heading className={ classNames.heading } element="h2">
                                                Waarom { pageContext.companyName }?
                                            </Heading>
                                            <ul className={ classNames.list }>
                                                { pageContext.strongPoints?.map((strongPoint, index) => (
                                                    <li className={ classNames.listItem } key={ index }>
                                                        <span className={ classNames.circle }>
                                                            <CheckIcon className={ classNames.icon } />
                                                        </span>
                                                        { strongPoint.message }
                                                    </li>
                                                )) }
                                            </ul>
                                        </Box>
                                    </div>
                                }
                            </div>

                            { priceTiers.slice(0,1).map((priceTier, index) => (
                                priceTier.advisor.reviews[0].recentReviews.length > 0 &&
                                    <Box key={ index } className={ classNames.reviews }>
                                        <div className={ classNames.header } id="reviews">
                                            <Heading className={ classNames.heading } element="h2" variant="medium">
                                                Klantervaringen bij { pageContext.companyName }
                                            </Heading>
                                        </div>
                                        <span className={ classNames.subHeader }>Op basis van klantervaringen die via Woninglabel.nl een aanvraag bij { pageContext.companyName } hebben gedaan.</span>
                                        <AdvisorReviews
                                            reviews={ pageContext.reviews.reviews }
                                            reviewsAverageScore={ pageContext.reviews.stats.averageScore }
                                            reviewsTotalCount={ pageContext.reviews.stats.totalReviews }
                                            reviewScores={ pageContext.reviews.scores }
                                        />
                                    </Box>
                                )) }
                        </div>
                    </div>
                </section>
            </div>
        </Main>
    );
};

export default Advisor;
